/* eslint-disable no-irregular-whitespace */

import { useCallback, useState } from 'react';
import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import { useMediaQuery, useTheme } from '@material-ui/core';

import {
  Container,
  ContainerLogo,
  Info,
  Bold,
  ContainerBottom,
  ContainerTop,
  InfoSubContainer,
  PrivacyLinksText,
  Copyright,
  ContainerArrow,
} from './styles';
import StartseLogoFooter from '../../../assets/svg/logo-s.svg';

const ReducedFooter: React.FC = () => {
  const [isOpened, setIsOpened] = useState(false);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down(780));

  const handleOpened = useCallback(() => {
    setIsOpened(!isOpened);
  }, [isOpened]);

  return (
    <>
      <div
        style={{
          position: 'relative',
          height: '200px',
        }}
      />
      <Container
        style={
          isOpened
            ? {}
            : {
                marginTop: 0,
                paddingTop: 0,
                height: '32px',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                border: 'none',
              }
        }
      >
        <ContainerArrow
          style={
            !isMobile
              ? {}
              : {
                  width: '3.3em',
                  boxShadow: '0px 0px 5px 0px #00000048',
                  right: '-65vw',
                }
          }
          onClick={handleOpened}
        >
          <p style={!isMobile ? {} : { display: 'none' }}>
            {!isOpened ? 'Mais Detalhes' : 'Menos Detalhes'}
          </p>
          <ArrowIcon
            style={
              isOpened
                ? {}
                : {
                    transform: 'rotate(180deg)',
                  }
            }
          />
        </ContainerArrow>
        <ContainerTop
          style={
            isOpened
              ? {}
              : {
                  position: 'absolute',
                  transform: 'translateY(100px)',
                  border: 'none',
                  padding: 0,
                  opacity: 0,
                }
          }
        >
          <ContainerLogo>
            <StartseLogoFooter />
          </ContainerLogo>
          <Info>
            <p>
              <Bold>Contato (De segunda a sexta, das 10h às 18h)</Bold>
            </p>
            <InfoSubContainer>
              <p>
                <Bold>Vendas:</Bold> (11) 94465-9807 / 99593-1524 
              </p>
              <p>
                <Bold>Atendimento:</Bold> (11) 96189-1624 / 972448378 /
                91132-2958
              </p>
              <p>
                <Bold>Business Partners:</Bold> (11) 95075-9513
              </p>
              <p>
                <Bold>E-mails:</Bold> atendimento@startse.com | dpo@startse.com
              </p>
            </InfoSubContainer>
          </Info>
        </ContainerTop>
        <ContainerBottom>
          <p
            style={
              isOpened
                ? {}
                : {
                    display: 'none',
                  }
            }
          >
            STARTSE TURISMO DE NEGOCIOS LTDA CNPJ: 32.291.526/0001-64 | STARTSE
            INFORMACOES E SISTEMAS S/A CNPJ: 24.554.736/0002-50
          </p>
          <PrivacyLinksText
            style={
              isOpened
                ? {}
                : {
                    display: 'none',
                  }
            }
          >
            <a
              href="https://docs.startse.com/politica-de-privacidade"
              rel="noreferrer"
              target="_blank"
            >
              Política de Privacidade e Termos
            </a>
            &nbsp; <p>|</p>
            <a
              href="https://docs.startse.com/politicas-gerais-de-cancelamento-e-remarcacao"
              rel="noreferrer"
              target="_blank"
            >
              Políticas Gerais de Cancelamento e Remarcação
            </a>
          </PrivacyLinksText>
          <Copyright
            style={
              isOpened
                ? {}
                : {
                    width: isMobile
                      ? 'calc(100% - 50px)'
                      : 'calc(100% - 150px)',
                    position: 'fixed',
                    bottom: 0,
                    backgroundColor: '#FFFFFF',
                  }
            }
          >
            Todos os direitos reservados &#169; startse.com
          </Copyright>
        </ContainerBottom>
      </Container>
    </>
  );
};

export default ReducedFooter;
